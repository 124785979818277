
const fogSideBarListInit = () => {
  // Global vars
  const sidebarFogRoot = document.querySelector('.fogTopRooms');
  if (!sidebarFogRoot) {
    return false;
  }
  const list = [...sidebarFogRoot.querySelectorAll('.fogTopRooms__list')];
  const tabs = [...sidebarFogRoot.querySelectorAll('nav a')];

  const activateList = (data) => {
    list.forEach((item) => {
      if (item.dataset.type === data.substring(1)) {
        item.style.display = 'block';
      } else {
        item.style.display = 'none';
      }
    });
  };

  const activateTab = (data) => {
    tabs.forEach((item) => {
      if (item.dataset.type === data) {
        item.classList.add('active');
        sidebarFogRoot.querySelector('.fogTopRooms__more').hash = data;
        activateList(data);
      } else {
        item.classList.remove('active');
      }
    });
  };

  const fogTabsListener = () => {
    function handleTabClick() {
      const { type } = this.dataset;
      activateTab(type);
    }

    tabs.forEach((item) => {
      item.addEventListener('click', handleTabClick);
    });
  };
  const isEmptyTab = (data) => !list.some((item) => item.dataset.type === data);

  const disableEmptyTab = () => {
    tabs.forEach((item) => {
      if (isEmptyTab(item.dataset.type.substring(1))) {
        item.classList.add('disabled');
      }
    });
  };

  disableEmptyTab();
  fogTabsListener();
  return true;
};

fogSideBarListInit();

document.addEventListener('turbo:load', () => {
  fogSideBarListInit();
});
